import api from "../axios_service.js";

export async function fetchPostagemColaboradores(id) {
  const response = await api.get(`/gestor/postagem-colaboradores/${id}`);
  return response.data.result;
}

export async function fetchPostagemColaboradoresColaborador(id) {
  const response = await api.get(`/colaborador/postagem-colaboradores/${id}`);
  return response.data.result;
}
