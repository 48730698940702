import api from "../axios_service.js";

// Busca Todas Redes Sociais
export async function fetchRedesSociais(filtros) {
  const response = await api.get(
    `/colaborador/redes-sociais${filtros ? filtros : ""}`
  );
  return response.data.result;
}

// Busca uma Rede Social
export async function fetchRedeSocial(id) {
  const response = await api.get(`/colaborador/redes-sociais/${id}`);
  return response.data.result;
}

// Atualiza uma Rede Social
export function putRedeSocial(id, social) {
  let body = {};
  for (let key in social) {
    body[key] = social[key];
  }
  return api.put(`/colaborador/redes-sociais/${id}`, body);
}

// Cria uma nova Rede Social
export function postRedeSocial(social) {
  let body = {};
  for (let key in social) {
    body[key] = social[key];
  }
  return api.post("/colaborador/redes-sociais/add", body);
}
